<template>
    <div class="week-list-item">
        <table-th wh="106px">
            <div class="week-label">
                <div>{{ type != '3' ? data.label.substring(0, 2) : data.label.split(" ")[0] }}</div>
                <div>{{ type != '3' ? data.label.substring(2) : data.label.split(" ")[2] }}</div>
            </div>
        </table-th>

        <div v-for="(item, index) of data.child" :key="index" class="week-item" :class="index%2==1 ? 'active':''">
            <div class="check-state" v-show="item.checkState && !item.operateShow">
                <img :src="require('@/assets/images/select-course.png')" alt="" srcset="">
            </div>
            <div v-if="item.subjectName" class="week-item-content" @mouseover="operateBtnShow(item)" @mouseleave="operateBtnHide(item)">
                        <div class="check-btn" @click.stop="changeCheckClick(data, item)">
                            <img src="@/assets/images/nomal-course.png" alt="" srcset="" v-show="!item.checkState">
                            <img :src="require('@/assets/images/select-course.png')" alt="" srcset="" v-show="item.checkState">
                        </div>
                <div class="item-content-multiLine" :class="item.operateShow?'active':''"  v-if="item.subjectName.length>4 ||item.teacherName.length>3 || (item.subjectName.length+item.teacherName.length)>6">
                    <div class="class-info">
                        <div class="label">{{ item.subjectName }}</div>
                    </div>
                    <div class="teachers-info">
                        <div class="teachers"  v-if="type!='2'">
                            <span><text-tooltip :content="'(' + item.teacherName + ')'" placement="bottom"></text-tooltip></span>
                       </div>
                        <div class="classname" v-else>
                            <span><text-tooltip :content="'(' + item.className + ')'" placement="bottom"></text-tooltip></span>
                       </div>
                   </div>
                </div>
                <div class="item-content" :class="item.operateShow?'active':''" v-else>

                    <div class="class-info">
                        <div class="label">{{ item.subjectName }}</div>
                    </div>
                    <div class="teachers-info">

                       <div class="teachers"  v-if="type!='2'">
                            <span><text-tooltip :content="'(' + item.teacherName + ')'" placement="bottom"></text-tooltip></span>
                       </div>
                        <div class="classname" v-else>
                            <span><text-tooltip :content="'(' + item.className + ')'" placement="bottom"></text-tooltip></span>
                       </div>
                   </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import TableTh from './Sub/TableTh.vue';
import TeachingResearchCourseScheduleTempFilter from '@/filters/TeachingResearchCourseScheduleTemp.js'
import TextTooltip from "@/components/scrollWrapper/Sub/TableData/TextTooltip.vue";
export default {
    name: "WeekListItem",
    filters: TeachingResearchCourseScheduleTempFilter,
    components: {
        TableTh,
        TextTooltip
    },
    props: {
        data: Object,
        index: Number,
        isTourClass: String,
        courseList: Array,
        showDownloadPDFType: Boolean,
        advanceTime: Number,
        type:String
    },
    computed: {
        prohibitAdding () {
            return require('@/assets/images/prohibit-adding.png')
        }
    },
    data() {
        return {
            textValue: '',
            timeValue: ''
        }
    },
    watch: {
        'this.data.child': {
            handler() {
                console.log('this.data.child',this.data.child)
            },
            deep: true
        },
    },
    methods: {
        showError (time) {
            return time._timestamp >= new Date().getTime()
        },
        lectureSetting (data, item) {
            let advanceTime = this.advanceTime;
            if (item._timestamp - new Date().getTime() <= advanceTime) {
                this.$eventDispatch('lectureSetting', {data: data, item:item })
            } else {
                this.$message.error('未开始的课程不允许巡课操作!')
            }
        },
        addWeekCourseSchedule (data, item) {
            console.log("新增课程吗",data,item)
            this.$eventDispatch('addWeekCourseSchedule', {data: data, item:item })
        },
        onceDelClick(data, item){
            console.log("---数据",data)
            this.$eventDispatch('onceDelCourse', {data: data, item:item })
        },
        changeCheckClick(data,item){
            this.$set(item, 'checkState', !item.checkState)
            if(this.type == '3'){
                item['time'] = data.time
                item['label'] = data.label.split(' ')[0]
            }
            this.$eventDispatch('changeCheck', {item:item,type:this.type })
        },
        operateBtnShow(item){
            item.operateShow=true
        },
        operateBtnHide(item){
            item.operateShow=false
        }
    }

}
</script>
<style lang="scss" scoped>
    .week-list-item {
        .week-label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
        }
        .week-item.active{
            background-color: #EDF4FA;
        }

        .week-item {
            height: 60px;
            box-sizing: border-box;
            border-right:  1px solid #61AFFF;
            border-bottom: 1px solid #61AFFF;
            position: relative;
            .check-state{
                position: absolute;
                top: 10px;
                right: 10px;
                img{
                    width: 16px;
                    height: 16px;
                }
            }

            .week-item-content {
                background-color: #fff;
                box-sizing: border-box;
                width: 106px;
                height: 59px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .check-btn{
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    img{
                        width: 15px;
                        height: 16px;
                    }
                }
                .class-patrol {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #9DA2A6;
                        cursor: pointer;
                    }
                    .class-patrol.current {
                        color: #3C7FFF;
                    }
                    .item-content-multiLine{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        .class-info {
                            .label {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #363B40;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap
                            }
                        }
                        .teachers-info {
                            display: flex;
                            align-items: center;
                            .teachers {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #6D7073;
                                width: 100px;
                            }
                            .classname{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6D7073;
                            width: 103px;
                     }
                        }
                    }

                .item-content.active{
                    margin-top: 12px;
                }
                .item-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;


                .class-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .label {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #363B40;
                        cursor: pointer;
                    }
                }
                .teachers-info {
                    display: flex;
                    align-items: center;
                    .teachers {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6D7073;
                    }
                    .classname{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6D7073;
                        width: 103px;
                     }
                }

                }


            }
            .week-item-content:hover {
                border: 1px dashed #499DF2;
                cursor: pointer;
            }
            .add-course-schedule {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .el-icon-plus {
                    color: #C8CACC;
                }
                .add {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B6BABF;
                }
                .no-scheduling {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B6BABF;
                }

            }
            .add-course-schedule:hover {
                .el-icon-plus {
                    color: #3C7FFF;
                }
                .add {
                    color: #3C7FFF;
                }
            }

        }
    }
    .item-content-multiLine {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        .teachers-info {
            width: 103px !important;
            text-align: center;
        }
    }
    .week-list-item .week-item .week-item-content {
        padding: 0;
    }
</style>
