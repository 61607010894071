<template>
    <div class="course-table-title">
        <div class="table-title" >
            <span v-if="type === '2'">教师课表</span>
            <span v-if="type === '3'">年级课表</span>
        </div>
    </div>
</template>
<script>

    export default {
        name: "CourseTableTitle",
        props: {
            type:String
        },
        computed: {        
        },
        mounted(){
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
    .course-table-title {
        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .table-title{
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #2B2E33;
        }
    }
</style>