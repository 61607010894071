<template>
    <div class="table-th" :style="{'width': wh}">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "TableTh",
    props: {
        wh: String,
    }
}
</script>

<style lang="scss" scoped>
    .table-th {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #61AFFF;
        border-bottom: 1px solid #61AFFF;
        background-color: #2288F1;
    }
</style>